<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-users></app-sidebar-users>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Users</span>
          <span>Owners</span>
        </div>
        <h2 class="az-content-title"> Owners</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">All Owners List</div>
            <p class="mg-b-20">List of all registered Owners.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Organisation</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of owners;">
                <th>{{r.id}}</th>
                <td>{{r.organisation}}</td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE OWNER</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addOwner(addForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    Organisation Name
                    <input type="text" formControlName="organisation" placeholder="Name" required class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Owner</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>