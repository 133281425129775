<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-stations></app-sidebar-stations>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Stations</span>
          <span>Station</span>
          <span>Terminals</span>
        </div>
        <h2 class="az-content-title">Service Station Terminals</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">All Stations List</div>
            <p class="mg-b-20">List of all registered Terminals.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>Station</th>
                <th>Terminal ID</th>
                <th>IMEI</th>
                <th>Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of terminals;">
                <th>{{r.serviceStation.stationName}}</th>
                <th>{{r.terminalId}}</th>
                <td>{{r.imei}}</td>
                <td>{{r.terminalType}}</td>
                <td>
                  <span *ngIf="r.status == 'ACTIVE'" class="badge badge-success">active</span>
                  <span *ngIf="r.status != 'ACTIVE'" class="badge badge-danger">inactive</span>
                </td>
                <td>
                  <span class="text-info pointer" title="View Terminal Transactions" (click)="viewTransactions(r.imei, r.id)">
                    <i class="fa fa-money-bill-wave"></i>
                  </span>
                  <span *ngIf="r.status == 'ACTIVE'" class="text-danger pointer ml-2" title="Deactivate Terminal" (click)="activateTerminal(r.id, false)">
                    <i class="fa fa-times-circle"></i>
                  </span>
                  <span *ngIf="r.status != 'ACTIVE'" class="text-success pointer ml-2" title="Activate Terminal" (click)="activateTerminal(r.id, true)">
                    <i class="fa fa-check-circle"></i>
                  </span>
                  <span class="text-primary pointer ml-2" title="Update Record" (click)="loadTerminal(r.id, 'edit')">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="text-warning pointer ml-2" title="Transfer Terminal" (click)="loadTerminal(r.id, 'transfer')">
                    <i class="fa fa-recycle"></i>
                  </span>
                  <span class="text-danger pointer ml-2" title="Delete Record" (click)="selectTerminal(r.id)">
                    <i class="fa fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE TERMINAL</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addTerminal(addForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      IMEI
                      <input type="text" formControlName="imei" placeholder="IMEI" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Terminal Id
                    <input type="text" formControlName="terminalId" placeholder="Terminal ID" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Type
                      <select formControlName="terminalType" class="form-control" required>
                          <option value="" selected disabled>choose type...</option>
                          <option value="FORE_COURT">Fore Court</option>
                          <option value="BACK_OFFICE">Back Office</option>
                      </select>
                    </div>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Terminal</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE TERMINAL</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateTerminal(editForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                      IMEI
                      <input type="text" formControlName="imei" placeholder="IMEI" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Terminal Id
                    <input type="text" formControlName="terminalId" placeholder="Terminal ID" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Type
                      <select formControlName="terminalType" class="form-control" required>
                          <option value="" selected disabled>choose type...</option>
                          <option value="FORE_COURT">Fore Court</option>
                          <option value="BACK_OFFICE">Back Office</option>
                      </select>
                    </div>
                </div>
              </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Terminal</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="transfer" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center w-100" id="">TRANSFER TERMINAL</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <section>
            <form (ngSubmit)="transferTerminal()">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Choose new station</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <select id="serviceStationId" class="form-control" required>
                        <option value="" selected disabled>choose station</option>
                        <option *ngFor="let s of stations" value="{{s.id}}" [disabled]="checkStation(s.id)">{{s.stationName}}</option>
                      </select>
                    </div>
                  </div>
                </div> 
                </div>
              <br><br>
              <div align="center">
                <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
                <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="button" (click)="transferTerminal()"><i class="fa fa-save"></i>&nbsp; Transfer Terminal</button>
              </div>
            </form>
          </section>
        </div>      
      </div>
    </div>
  </div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE TERMINAL</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete a Terminal!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="deleteTerminal()"><i class="fa fa-trash-alt"></i>&nbsp; Delete Terminal</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>