import { UsersService } from './../../shared/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  addForm: FormGroup;

  constructor(
    private toast: ToastrService,
    private fb: FormBuilder,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      matchingPassword: ['', Validators.required],
    });
  }

  changePassword(form: FormGroup){
    // console.log(form.value);

    if(form.valid){
      this.userService.changeUserPassword(form.value).subscribe(
        resp => {
          // console.log(resp);
          this.toast.success('Password Changed Succesfully ');
          this.addForm.reset;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
    
  }

}
