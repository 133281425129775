<div class="az-content-left az-content-left-components">
    <div class="component-item">
      
      <label>Settings</label> <br>
      <nav class="nav flex-column">
        <a routerLink="/admin/settings/cards" routerLinkActive="active" class="nav-link">Cards</a>
      </nav>

      <br><br>

      <label>Configurations</label> <br>

      <br><br>

      <label>Others</label> <br>
      <nav class="nav flex-column">
        <a routerLink="/admin/change-password" routerLinkActive="active" class="nav-link">Change Password</a>
      </nav>

    </div><!-- component-item -->

</div>