import { NgxPaginationModule } from 'ngx-pagination';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminUsersRoutingModule } from './admin-users-routing.module';
import { MngUsersComponent } from './mng-users/mng-users.component';
import { MngRolesComponent } from './mng-roles/mng-roles.component';
import { MngTrailsComponent } from './mng-trails/mng-trails.component';
import { MngActivitiesComponent } from './mng-activities/mng-activities.component';
import { MngPermissionsComponent } from './mng-permissions/mng-permissions.component';
import { SidebarUsersComponent } from './sidebar-users/sidebar-users.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MngManagersComponent } from './mng-managers/mng-managers.component';


@NgModule({
  declarations: [MngUsersComponent, MngRolesComponent, MngTrailsComponent, MngActivitiesComponent, MngPermissionsComponent, SidebarUsersComponent, MngManagersComponent],
  imports: [
    CommonModule,
    AdminUsersRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [SidebarUsersComponent]
})
export class AdminUsersModule { }
