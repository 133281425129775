<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-users></app-sidebar-users>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column w-100">
        <div class="az-content-breadcrumb">
          <span>Users</span>
          <span>All</span>
        </div>
        <h2 class="az-content-title">System Users</h2>

        <div class="container d-block">
          <div class="row">
            <div class="col-md-8">
              <div class="az-content-label mg-b-5">All Users List</div>
              <p class="mg-b-20">List of all registered Users.</p>
            </div>
            <div class="col-md-4 mt-md-0 mt-3" align="right">
              <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
                NEW &nbsp;<i class="fa fa-plus-circle"></i>
              </button>
            </div>
          </div>
  
          <div class="row">
            <div class="table-responsive mt-4">
              <table class="table table-hover mg-b-0">
                <thead>
                  <tr>
                    <th>Reference</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="users">
                  <tr *ngFor="let r of users | paginate: config;">
                    <td>{{r.id}}</td>
                    <td>{{r.firstname}} {{r.lastname}}</td>
                    <td>{{r.email}}</td>
                    <td>{{r.username}}</td>
                    <td>{{removeUnderscore(r.roles[0].name)}}</td>
                    <td>
                      <span class="badge badge-success" *ngIf="r.enabled == 'Active'">active</span>
                      <span class="badge badge-danger" *ngIf="r.enabled != 'Active'">inactive</span>
                    </td>
                     <td>
                      <span *ngIf="r.enabled != 'Active'" title="Activate User" class="pointer text-success" (click)="activateUser(r.id, true)">
                        <i class="fa fa-user-check"></i>
                      </span>
                      <span *ngIf="r.enabled == 'Active'" title="Deactivate User" class="pointer text-danger" (click)="activateUser(r.id, false)">
                        <i class="fa fa-user-times"></i>
                      </span>
                      <span class="pointer text-primary ml-1" title="Update User" (click)="loadUser(r.id)">
                        <i class="fa fa-edit"></i>
                      </span>
                      <span class="pointer text-danger ml-1" title="Delete User" (click)="selectUser(r.username)">
                        <i class="fa fa-trash-alt"></i>
                      </span>
                  </tr>
                </tbody>
              </table>
              <br>
              <span *ngIf="users" id="entriesCounter" class="text-secondary float-right">Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ config.totalItems }}.</span>
              <br>
              <div *ngIf="users">
                  <div class="d-flex" id="controlsArea">
                      Items:
                      <select class="pageItems" (change)="handlePageSizeChange($event)">
                          <option *ngFor="let size of pageSizes" [ngValue]="size">
                              {{ size }}
                          </option>
                      </select>
                      <pagination-controls
                      class=""
                      responsive="true"
                      (pageChange)="handlePageChange($event)"
                      ></pagination-controls> 
                  </div> 
              </div>
            </div><!-- table-responsive -->
          </div>
        </div>

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE USER</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="createUser(addForm)">
            <div class="container">
              <!-- <div class="form-group">
                <label>Organisation</label>
                <select formControlName="organisation" required class="form-control">
                  <option value="" selected disabled>choose organisation...</option>
                  <option value="FUDZO">Fudzo</option>
                  <option value="THULI">Thuli</option>
                </select>
              </div> -->
              <div class="form-group">
                <label>Firstname</label>
                <input formControlName="firstName" required type="text" class="form-control" placeholder="Enter your firstname">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Lastname</label>
                <input formControlName="lastName" required type="text" class="form-control" placeholder="Enter your lastname">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Email</label>
                <input formControlName="email" required type="text" class="form-control" placeholder="Enter your email">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Username</label>
                <input formControlName="username" required type="text" class="form-control" placeholder="Enter your username">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Role</label>
                <select formControlName="roleId" required class="form-control">
                  <option value="" selected disabled>choose role...</option>
                  <option *ngFor="let r of roles;" value="{{r.id}}">{{removeUnderscore(r.name)}}</option>
                </select>
              </div><!-- form-group -->
              <div class="form-group">
                <label>Password</label>
                <input formControlName="password" required type="password" class="form-control" placeholder="Enter your password">
              </div><!-- form-group -->
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add User</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE USER</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateUser(editForm)">
            <div class="container">
              <div class="form-group">
                <label>Firstname</label>
                <input formControlName="firstName" required type="text" class="form-control" placeholder="Enter your firstname">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Lastname</label>
                <input formControlName="lastName" required type="text" class="form-control" placeholder="Enter your lastname">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Email</label>
                <input formControlName="email" required type="text" class="form-control" placeholder="Enter your email">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Username</label>
                <input formControlName="username" required type="text" class="form-control" placeholder="Enter your username">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Role</label>
                <select formControlName="roleId" required class="form-control">
                  <option value="" selected disabled>choose role...</option>
                  <option *ngFor="let r of roles;" value="{{r.id}}">{{removeUnderscore(r.name)}}</option>
                </select>
              </div><!-- form-group -->
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update User</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE USER</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete a User!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" (click)="deleteUser()" type="button"><i class="fa fa-trash-alt"></i>&nbsp; Delete User</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>