import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  return: string = '';

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    let utype = this.authService.getUserType();

    this.return = `/${utype}/home`;
  }

}
