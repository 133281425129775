<div class="az-content az-content-dashboard">
    <div class="container">
      <div class="az-content-body">
        <div class="az-dashboard-one-title">
          <div> 
            <h2 class="az-dashboard-title">Welcome back <span class="text-secondary">{{dname}}</span></h2>
            <p class="az-dashboard-text">Your account dashboard analytics.</p>
          </div>
          <div class="az-content-header-right">
            <div class="media">
              <div class="media-body">
                <label>Date</label>
                <h6>{{date}}</h6>
              </div><!-- media-body -->
            </div><!-- media -->
          </div>
        </div><!-- az-dashboard-one-title -->

        <div class="az-dashboard-nav"> 
          <nav class="nav">
            <a class="nav-link active" data-toggle="tab" href="#">Overview</a>
          </nav>
        </div>

        <div class="row row-sm mg-b-20 mg-lg-b-0">
          <div class="col-md-6 col-lg-4">
            <div class="card h-100 card-dashboard-five">
              <div class="card-header">
                <h6 class="card-title">Account Details</h6>
                <span class="card-text">Tells you details about your account.</span>
              </div><!-- card-header -->
              <div class="card-body row row-sm">
                <div class="col-12 col-md-12 mt-2 d-sm-flex align-items-center">
                  <div class="card-chart text-info">
                    <span class="peity-bar">
                      <i class="fa fa-2x fa-user"></i>
                    </span>
                  </div>
                  <div>
                    <label>Account Type</label>
                    <h4>{{atype}}</h4>
                  </div>
                </div><!-- col -->
                <div class="col-12 col-md-12 mt-2 d-sm-flex align-items-center">
                  <div class="card-chart text-success">
                    <span class="peity-bar">
                      <i class="fa fa-2x fa-id-card-alt"></i>
                    </span>
                  </div>
                  <div *ngIf="atype == 'INDIVIDUAL'">
                    <label>Account Name</label>
                    <h4>{{dname}}</h4>
                  </div>
                  <div *ngIf="atype == 'COMPANY'">
                    <label>Account Name</label>
                    <h4>{{accInfo?.companyName}}</h4>
                  </div>
                </div><!-- col -->
              </div><!-- card-body -->
            </div><!-- card-dashboard-five -->
          </div><!-- col-md-4 -->
          <div class="col-lg-8 mg-t-20 mg-lg-t-0" style="height: 40vh;">
            <div class="card card-dashboard-four">
              <div class="card-body row">
                <div class="col-md-5">
                  <h6 class="card-title">Cards Statistics</h6>
                  <div class="card-body">
                    <div *ngFor="let c of cards" class="az-list-item mt-4">
                      <div>
                        <h6>{{c.cardStatus}} Cards</h6>
                        <span *ngIf="c.cardStatus == 'Active'" class="badge badge-success text-white">active</span>
                        <span *ngIf="c.cardStatus == 'New'" class="badge badge-primary text-white">new</span>
                        <span *ngIf="c.cardStatus == 'Blocked'" class="badge badge-danger text-white">active</span>
                      </div>
                      <div>
                        <h6 class="tx-primary">{{c.totalCards}}</h6>
                        <span>{{c.totalBalance | currency: 'USD' : 'symbol' : '1.2-2'}}</span>
                      </div>
                    </div><!-- list-group-item -->
                  </div><!-- card-body -->
                </div><!-- card-header -->
                <div class="col-md-7 mt-2 mt-md-0 align-items-right">
                  <div class="chart col-md-8"><canvas #doughnutCanvas style="height:70%; width:70%"></canvas></div>
                </div><!-- col -->
              </div><!-- card-body -->
            </div><!-- card-dashboard-four -->
          </div><!-- col -->
        </div><!-- row -->

      </div><!-- az-content-body -->
    </div>
  </div>