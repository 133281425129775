import { MngManagersComponent } from './../admin/admin-users/mng-managers/mng-managers.component';
import { ClientHomeComponent } from './client-home/client-home.component';
import { AuthGuard } from './../shared/utilities/auth.guard';
import { ClientContainerComponent } from './client-container/client-container.component';
import { NotFoundComponent } from './../shared/components/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'client',
    component: ClientContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: ClientHomeComponent },
      { path: 'users', component: MngManagersComponent },

      // admin modules
      { path: 'accounts', loadChildren: () => import('./client-accounts/client-accounts.module').then(m => m.ClientAccountsModule) },
      { path: 'transactions', loadChildren: () => import('./client-transactions/client-transactions.module').then(m => m.ClientTransactionsModule) },

      // not found
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
