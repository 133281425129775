import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService, 
    public router: Router, 
    private toast: ToastrService
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const url: string = state.url;
      // console.log(url);
      
      if (this.authService.isLoggedIn !== true) {
        this.toast.error("Access not allowed!");
        this.router.navigate(['/login'], {
          queryParams: {
            returnUrl: url
          }
        });
      }

    return true;
  }
  
}
