import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminSettingsRoutingModule } from './admin-settings-routing.module';
import { OwnerStngsComponent } from './owner-stngs/owner-stngs.component';
import { SidebarSettingsComponent } from './sidebar-settings/sidebar-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AdminUsersModule } from '../admin-users/admin-users.module';


@NgModule({
  declarations: [OwnerStngsComponent, SidebarSettingsComponent],
  imports: [
    CommonModule,
    AdminSettingsRoutingModule,
    ReactiveFormsModule,
    AdminUsersModule
  ],
  exports: [SidebarSettingsComponent]
})
export class AdminSettingsModule { }
