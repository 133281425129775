import { AuthService } from './../../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-users',
  templateUrl: './sidebar-users.component.html',
  styleUrls: ['./sidebar-users.component.css']
})
export class SidebarUsersComponent implements OnInit {

  uType: string;

  constructor(
    private authService: AuthService
  ) { 
    this.uType = authService.getUserType();
  }

  ngOnInit(): void {
  }

}
