import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mng-activities',
  templateUrl: './mng-activities.component.html',
  styleUrls: ['./mng-activities.component.css']
})
export class MngActivitiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
