import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  encrypt(data) {
    // console.log(data);
    return data;
    
    // data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    // let encData = data.toString();
    // return encData;
  }

  decrypt(data) {
    if(data){
      return data;

      // data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      // let decData = data.toString(CryptoJS.enc.Utf8);
      // return decData;
    }
  }
  
}
