import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-stations',
  templateUrl: './sidebar-stations.component.html',
  styleUrls: ['./sidebar-stations.component.css']
})
export class SidebarStationsComponent implements OnInit {

  constructor(
    public toast : ToastrService
  ) { }

  ngOnInit(): void {
  }

  

}
