import { StorageService } from './../../../shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StationService } from './../../../shared/services/station.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/shared/services/users.service';
declare var $: any;

@Component({
  selector: 'app-mng-attendants',
  templateUrl: './mng-attendants.component.html',
  styleUrls: ['./mng-attendants.component.css']
})
export class MngAttendantsComponent implements OnInit {

  users: any;
  stationId: number;
  attendants: any;
  selectedAttendant: any;
  attendantId: number;
  stations: any;

  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private fb : FormBuilder,
    private toast : ToastrService,
    private stationService : StationService,
    private activateRoute : ActivatedRoute,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.stationId = params["id"];
    });

    this.getAttendants();

    this.addForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      role: ['', Validators.required],
      serviceStationId: [this.stationId, Validators.required],
    });

    this.editForm = this.fb.group({
      id : 0,
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      mobile: '',
      serviceStationId: this.stationId,
    });

    this.getStations();

  }

  getAttendants(){
    if(this.stationId){
      this.stationService.getPaginatedAttendantsByStation(0, 100, this.stationId).subscribe(
        data => {
          // console.table(data);
  
          this.attendants = data.content;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
  }

  addAttendant(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.createAttendant(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#add').modal('hide');
          this.getAttendants();
          this.toast.success('Attendant Created Succesfully ');
          this.addForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            mobile: ['', Validators.required],
            role: ['ORDINARY', Validators.required],
            serviceStationId: [this.stationId, Validators.required],
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  selectAttendant(ref, typ){
    ref ? this.attendantId = ref : false; 

    typ == 'delete' ? $('#delete').modal('show') : $('#setPIN').modal('show');
  }

  deleteAttendant(){
    if(this.attendantId){
      this.stationService.deleteAttendant(this.attendantId).subscribe(
        resp => {
          // console.log(resp);
          $('#delete').modal('hide');
          this.getAttendants();
          this.toast.success('Attendant Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  attendantPIN(){
    let data =  $('#newpin').val();
    // console.log(data);
    if(data){
      this.stationService.setAttendantPin(this.attendantId ,data).subscribe(
        resp => {
          // console.log(resp);
          $('#setPIN').modal('hide');
          this.getAttendants();
          $('#newpin').val('');
          this.toast.success('Attendant PIN set Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }


  updateAttendant(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.updateAttendant(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#edit').modal('hide');
          this.getAttendants();
          this.toast.success('Attendant Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  loadAttendant(ref, typ){
    if(ref){
      this.attendants.forEach(r => {
        (r.id == ref) ? this.selectedAttendant = r : '';
      });
      
      if(this.selectedAttendant){
        this.editForm = this.fb.group({
          id: [this.selectedAttendant.id],
          firstName: [this.selectedAttendant.firstName, Validators.required],
          lastName: [this.selectedAttendant.lastName, Validators.required],
          mobile: [this.selectedAttendant.mobile, Validators.required],
          email: [this.selectedAttendant.email, Validators.required],
          role: [this.selectedAttendant.role, Validators.required],
        });

        this.attendantId = ref;

        (typ == 'edit') ? $('#edit').modal('show') : $('#transfer').modal('show');
      }
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  transferAttendant(){
    let data = { 'serviceStationId': $('#serviceStationId').val(), attendantId: this.attendantId };
    // console.log(data);
    if(data){
      this.stationService.transferAttendant(data).subscribe(
        resp => {
          console.log(resp);
          $('#transfer').modal('hide');
          this.getAttendants();
          this.toast.success('Attendant Transferred Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  getStations(){
    this.stationService.getPaginatedStations(0, 100).subscribe(
      data => {
        // console.table(data);

        this.stations = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  checkStation(ref){
    return (ref == this.stationId);
  }

  activateAttendant(ref, status: boolean){
    if(ref){
      this.stationService.attendantStatus(ref, status).subscribe(
        resp => {
          // console.log(resp);
          // $('#delete').modal('hide');
          this.getAttendants();
          this.toast.success('Status Changed Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  viewTransactions(ref, name){
    sessionStorage.setItem('trans-mode', this.storageService.encrypt('attendant'));
    sessionStorage.setItem('trans-disp', this.storageService.encrypt(name));

    this.router.navigate(['/admin/stations/view-transactions/' + ref]);
  }

}
