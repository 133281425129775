import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  baseURL: string = environment.baseURL + '/fudzo-main-service/api/v1';

  constructor(
    private http : HttpClient
  ) { }

  // manage service stations
  getStationId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/service-station/${ref}`);
  }

  getPaginatedStations(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/service-station/2/${page}/${size}`);
  }

  createStation(data): Observable<any> {
    return this.http.post(`${this.baseURL}/service-station`, data);
  }

  updateStation(data): Observable<any> {
    return this.http.put(`${this.baseURL}/service-station/edit`, data);
  }
  
  deleteStation(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/service-station/${ref}`);
  }

  // manage station terminals
  getTerminalId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/terminal/${ref}`);
  }

  getPaginatedTerminals(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/terminal/${page}/${size}`);
  }

  getPaginatedTerminalsByStation(page, size, station): Observable<any> {
    return this.http.get(`${this.baseURL}/terminal/${station}/${page}/${size}`);
  }

  createTerminal(data): Observable<any> {
    return this.http.post(`${this.baseURL}/terminal`, data);
  }

  updateTerminal(data): Observable<any> {
    return this.http.put(`${this.baseURL}/terminal/edit?terminalId=${data.terminalId}`, data);
  }
  
  deleteTerminal(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/terminal/${ref}`);
  }

  transferTerminal(data, ref): Observable<any> {
    return this.http.put(`${this.baseURL}/terminal/transfer?terminalId=${ref}`, data);
  }

  terminalStatus(ref, status: boolean): Observable<any> {
    return status ?
            this.http.put(`${this.baseURL}/terminal/activate?terminalId=${ref}`, null) :
            this.http.put(`${this.baseURL}/terminal/deactivate?terminalId=${ref}`, null);
  }
  
  // manage station products
  getProductId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/product/${ref}`);
  }

  getPaginatedProducts(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/product/${page}/${size}`);
  }

  getPaginatedProductsByStation(page, size, station): Observable<any> {
    return this.http.get(`${this.baseURL}/product/${station}/${page}/${size}`);
  }

  createProduct(data): Observable<any> {
    return this.http.post(`${this.baseURL}/product`, data);
  }

  updateProduct(data): Observable<any> {
    return this.http.put(`${this.baseURL}/product/edit?productId=${data.id}`, data);
  }
  
  deleteProduct(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/product/${ref}`);
  }

  changeProductPrice(data): Observable<any> {
    return this.http.put(`${this.baseURL}/product/price`, data);
  }

  // manage station attendants
  getAttendantId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/attendant/${ref}`);
  }

  getPaginatedAttendants(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/attendant/${page}/${size}`);
  }

  getPaginatedAttendantsByStation(page, size, station): Observable<any> {
    return this.http.get(`${this.baseURL}/attendant/${station}/${page}/${size}`);
  }

  createAttendant(data): Observable<any> {
    return this.http.post(`${this.baseURL}/attendant`, data);
  }

  updateAttendant(data): Observable<any> {
    return this.http.put(`${this.baseURL}/attendant/edit?attendant-id=${data.id}`, data);
  }
  
  deleteAttendant(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/attendant/${ref}`);
  }

  transferAttendant(data): Observable<any> {
    return this.http.put(`${this.baseURL}/attendant/transfer`, data);
  }

  setAttendantPin(ref, pin): Observable<any> {
    return this.http.put(`${this.baseURL}/attendant/set-pin?attendantId=${ref}&pin=${pin}`, null);
  }

  attendantStatus(ref, status: boolean): Observable<any> {
    return status ?
            this.http.put(`${this.baseURL}/attendant/activate?attendantId=${ref}`, null) :
            this.http.put(`${this.baseURL}/attendant/deactivate?attendantId=${ref}`, null);
  }

}
