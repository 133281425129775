import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminStationsRoutingModule } from './admin-stations-routing.module';
import { SidebarStationsComponent } from './sidebar-stations/sidebar-stations.component';
import { MngStationsComponent } from './mng-stations/mng-stations.component';
import { MngProductsComponent } from './mng-products/mng-products.component';
import { MngAttendantsComponent } from './mng-attendants/mng-attendants.component';
import { MngTerminalsComponent } from './mng-terminals/mng-terminals.component';
import { StationTransactionsComponent } from './station-transactions/station-transactions.component';


@NgModule({
  declarations: [SidebarStationsComponent, MngStationsComponent, MngProductsComponent, MngAttendantsComponent, MngTerminalsComponent, StationTransactionsComponent],
  imports: [
    CommonModule,
    AdminStationsRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ]
})
export class AdminStationsModule { }
