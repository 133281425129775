import { ReactiveFormsModule } from '@angular/forms';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { AdminStationsModule } from './admin-stations/admin-stations.module';
import { AdminAccountsModule } from './admin-accounts/admin-accounts.module';
import { AdminSettingsModule } from './admin-settings/admin-settings.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminContainerComponent } from './admin-container/admin-container.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


@NgModule({
  declarations: [AdminContainerComponent, AdminHomeComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    AdminSettingsModule,
    AdminAccountsModule,
    AdminStationsModule,
    AdminUsersModule,
    ReactiveFormsModule
  ]
})
export class AdminModule { }
