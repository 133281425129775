import { MngManagersComponent } from './mng-managers/mng-managers.component';
import { OwnerStngsComponent } from './../admin-settings/owner-stngs/owner-stngs.component';
import { MngPermissionsComponent } from './mng-permissions/mng-permissions.component';
import { MngActivitiesComponent } from './mng-activities/mng-activities.component';
import { MngTrailsComponent } from './mng-trails/mng-trails.component';
import { MngRolesComponent } from './mng-roles/mng-roles.component';
import { MngUsersComponent } from './mng-users/mng-users.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'all', pathMatch: 'full' },
  { path: 'all', component: MngUsersComponent },
  { path: 'roles', component: MngRolesComponent },
  { path: 'owners', component: OwnerStngsComponent },
  { path: 'audit-trails', component: MngTrailsComponent },
  // { path: 'managers', component: MngManagersComponent },
  { path: 'permissions', component: MngPermissionsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminUsersRoutingModule { }
