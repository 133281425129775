import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AuthGuard } from './../shared/utilities/auth.guard';
import { AdminContainerComponent } from './admin-container/admin-container.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '../shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: AdminHomeComponent },
      { path: 'change-password', component: ChangePasswordComponent },

      // admin modules
      { path: 'settings', loadChildren: () => import('./admin-settings/admin-settings.module').then(m => m.AdminSettingsModule) },
      { path: 'stations', loadChildren: () => import('./admin-stations/admin-stations.module').then(m => m.AdminStationsModule) },
      { path: 'accounts', loadChildren: () => import('./admin-accounts/admin-accounts.module').then(m => m.AdminAccountsModule) },
      { path: 'users', loadChildren: () => import('./admin-users/admin-users.module').then(m => m.AdminUsersModule) },
      { path: 'transactions', loadChildren: () => import('./admin-transactions/admin-transactions.module').then(m => m.AdminTransactionsModule) },

      // not found
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
