import { TransactionService } from './../../../shared/services/transaction.service';
import { StorageService } from './../../../shared/services/storage.service';
import { OwnerService } from './../../../shared/services/owner.service';
import { Router } from '@angular/router';
import { AccountsService } from './../../../shared/services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {StationService} from "../../../shared/services/station.service";
declare var $: any;

@Component({
  selector: 'app-mng-individual',
  templateUrl: './mng-individual.component.html',
  styleUrls: ['./mng-individual.component.css']
})
export class MngIndividualComponent implements OnInit {

  accounts: any;
  accounts2: any;
  stations: any;
  owners: any;
  selectedAccount: any;
  accountId: number;
  loading: boolean;

  addForm: FormGroup;
  editForm: FormGroup;
  topupForm: FormGroup;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private fb : FormBuilder,
    private toast : ToastrService,
    private accountsService : AccountsService,
    private transactionService : TransactionService,
    private stationService : StationService,
    private router : Router,
    private ownerService : OwnerService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.getAccounts();
    this.getStations();


    this.addForm = this.fb.group({
      firstname: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', Validators.required],
      lastname: ['', Validators.required],
      idNumber: ['', Validators.required],
      organisationId: ['2', Validators.required]
    });

    this.editForm = this.fb.group({
      id : 0,
      firstname: '',
      lastname: '',
      mobile: '',
      email: '',
      organisationId: '',
      idNumber: ''
    });

    this.getCompanies();

    this.topupForm = this.fb.group({
      account: ['', Validators.required],
      amount: ['', Validators.required],
      source: ['', Validators.required],
      currency: ['USD', Validators.required],
      transactionReference: ['', Validators.required],
      serviceStationId: ['']
    });
  }

  handlePageChange(event) {
    this.page = event;
    this.getAccounts();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAccounts();
  }

  getAccounts(){
    this.accountsService.getPaginatedIndividuals(this.page - 1, this.pageSize).subscribe(
      data => {
        // console.table(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }

        this.accounts = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  addAccount(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.accountsService.createIndividual(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#add').modal('hide');
          this.getAccounts();
          this.toast.success('Account Created Succesfully ');
          this.addForm = this.fb.group({
            firstName: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['', Validators.required],
            lastName: ['', Validators.required],
            idNumber: ['', Validators.required],
            organisationId: ['2', Validators.required]
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  selectAccount(ref){
    ref ? this.accountId = ref : false; $('#delete').modal('show');
  }

  deleteAccount(){
    if(this.accountId){
      this.accountsService.deleteIndividual(this.accountId).subscribe(
        resp => {
          console.log(resp);
          $('#delete').modal('hide');
          this.getAccounts();
          this.toast.success('Account Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  updateAccount(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.accountsService.updateIndividual(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#edit').modal('hide');
          this.getAccounts();
          this.toast.success('Account Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  loadAccount(ref){
    if(ref){
      this.accounts.forEach(r => {
        (r.accountNumber == ref) ? this.selectedAccount = r : '';
      });

      if(this.selectedAccount){
        this.editForm = this.fb.group({
          individualAccountId: [this.selectedAccount.id, Validators.required],
          firstname: [this.selectedAccount.firstname, Validators.required],
          lastname: [this.selectedAccount.lastname, Validators.required],
          email: [this.selectedAccount.email, Validators.required],
          mobile: [this.selectedAccount.mobile, Validators.required],
          idNumber: [this.selectedAccount.idNumber, Validators.required]
        });

        $('#edit').modal('show');
      }
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  openAccountCards(ref){
    if(ref){
      sessionStorage.setItem('mode', this.storageService.encrypt('individual'));
      this.router.navigate(['/admin/accounts/cards/' + ref]);
    }
  }

  openTransactions(ref, name){
    if(ref){
      sessionStorage.setItem('trans-mode', this.storageService.encrypt('account'));
      sessionStorage.setItem('trans-disp', this.storageService.encrypt(name));

      this.router.navigate(['/admin/accounts/transactions/' + ref]);
    }
  }

  getCompanies(){
    this.ownerService.getPaginatedOwners(0, 100).subscribe(
      data => {
        // console.table(data);

        this.owners = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  topUpAccount(ref){
    if(ref){
      this.topupForm.patchValue({account: ref});

      $('#topup').modal('show');
    }
    else{
      this.toast.warning('No Account Found.')
    }
  }

  topupAccount(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.transactionService.individualTopUp(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#topup').modal('hide');
          this.getAccounts();
          this.toast.success('Account Top-Up Succesfull');
          this.topupForm = this.fb.group({
            account: ['', Validators.required],
            source: ['', Validators.required],
            amount: ['', Validators.required],
            currency: ['USD', Validators.required],
            transactionReference: ['', Validators.required],
            serviceStationId: ['']
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  switchDisplay(){
    this.accounts2 = null;
    $('#searchValue').val('');
  }

  searchAccount(){
    let ref = $('#searchValue').val();
    let typ = $('#searchType').val();

    if(ref && typ){
      this.loading = true;
      this.accountsService.searchAccounts('INDIVIDUAL', ref, 2, typ).subscribe(
        data => {
          // console.log(data);
          this.accounts2 = data;
          this.loading = false;
        },
        err => {
          console.error(err);
          this.loading = false;
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Search Fields.');
    }
  }

  getStations(){
    this.stationService.getPaginatedStations(0, 100).subscribe(
      data => {
        // console.table(data);

        this.stations = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
        else if(err.error?.message){
          this.toast.error(err.error.message);
        } else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

}
