<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-settings></app-sidebar-settings>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Settings</span>
          <span>Cards</span>
          <span>All</span>
        </div>
        <h2 class="az-content-title">Account Cards</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">All Cards List</div>
            <p class="mg-b-20">List of all registered Cards for this Account.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <!-- <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button> -->
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Organisation</th>
                <th>Pan</th>
                <th>Balance</th>
                <th>Status</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody *ngIf="cards">
              <tr *ngFor="let r of cards | paginate: config;">
                <th>{{r.id}}</th>
                <td>{{r.owner.organisation}}</td>
                <td>{{r.pan}}</td>
                <td>{{r.balance | currency: 'USD' : 'symbol' : '1.2-2'}}</td>
                <td>
                  <span class="badge badge-success" *ngIf="r.status == 'Active'">active</span>
                  <span class="badge badge-warning" *ngIf="r.status != 'Active'">not active</span>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <span *ngIf="cards" id="entriesCounter" class="text-secondary float-right">Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ config.totalItems }}.</span>
          <br>
          <div *ngIf="cards">
              <div class="d-flex" id="controlsArea">
                  Items:
                  <select class="pageItems" (change)="handlePageSizeChange($event)">
                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                          {{ size }}
                      </option>
                  </select>
                  <pagination-controls
                  class=""
                  responsive="true"
                  (pageChange)="handlePageChange($event)"
                  ></pagination-controls> 
              </div> 
          </div>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE CARD</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete a Card!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button"><i class="fa fa-trash-alt"></i>&nbsp; Delete Card</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>