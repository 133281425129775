<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-users></app-sidebar-users>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column w-100">
        <div class="az-content-breadcrumb">
          <span>Users</span>
          <span>Roles</span>
          <span>Permissions</span>
        </div>
        <h2 class="az-content-title">Role Permissions</h2>

        <div class="container d-block">
          <div class="row">
            <div class="col-md-8">
              <div class="az-content-label mg-b-5">All Roles Permissions List</div>
              <p class="mg-b-20">List of all registered Roles & Permissions.</p>
            </div>
            <div class="col-md-4 mt-md-0 mt-3" align="right">
              <!-- <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
                NEW &nbsp;<i class="fa fa-plus-circle"></i>
              </button> -->
            </div>
          </div>
  
          <div class="row mt-4">

            <div class="card col-md-3">
              <div class="card-header">
                  <h4 class="text-black-50">
                      <i class="mdi mdi-information"></i>&nbsp;&nbsp;All Roles
                  </h4>
              </div>
              <div class="card-body" style="padding: 3%;">
                  
                  <div *ngFor="let r of roles;" class="myRoles" (click)="selectRole(r.id)">
                      <span *ngIf="selectedRole == r.id" class="badge badge-primary">></span>
                      {{r.id}} - {{removeUnderscore(r.name)}}
                  </div>
                  
              </div>
            </div>

            <div class="col-md-9 pl-3">
              <div class="row col-md-12" *ngIf="roles">
                <div class="col-md-5" align="center">
                    <form [formGroup]="addForm" (ngSubmit)="addPermission(addForm)">
                        <div class="form-group">
                            Un-Assigned Permissions <em *ngIf="addForm.get('authoritiesId').hasError('required')" style="color: red;"> *</em><br>
                            <em class="text-black-50" style="font-size: 12px;">&nbsp;&nbsp;&nbsp;Press 'Ctrl' to choose multiple</em>
                            <select class="form-control" style="height: 300px;" formControlName="authoritiesId" multiple>
                                <option *ngFor="let p of unAssPerms;" [ngValue]="p.id">{{removeUnderscore(p.authority)}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-sm btn-outline-primary">
                                Add Permissions
                            </button>
                        </div>
                    </form>
                </div>
                <div class="col-md-2"  align="center" style="margin-top: 8%;">
                    <span>
                        <i class="fas fa-arrow-right"></i>
                    </span>
                    <div></div>
                    <span>
                        <i class="fas fa-arrow-left"></i>
                    </span>
                </div>
                <div class="col-md-5"  align="center">
                    <form [formGroup]="removeForm" (ngSubmit)="removePermission(removeForm)">
                        <div class="form-group">
                            Assigned Permissions <em *ngIf="removeForm.get('authoritiesId').hasError('required')" style="color: red;"> *</em><br>
                            <em class="text-black-50" style="font-size: 12px;">&nbsp;&nbsp;&nbsp;Press 'Ctrl' to choose multiple</em>
                            <select class="form-control" style="height: 300px;" formControlName="authoritiesId" multiple>
                                <option *ngFor="let p of assPerms;" [ngValue]="p.id">{{removeUnderscore(p.authority)}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-sm btn-outline-primary">
                                Remove Permissions
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            </div>
          </div>
        </div>

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->