import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  baseURL: string = environment.baseURL + '/fudzo-main-service/api/v1';

  constructor(
    private http : HttpClient
  ) { }

  getOwnerId(ref): Observable<any> {
    return this.http.get(`${this.baseURL}/owner/${ref}`);
  }

  getPaginatedOwners(page, size): Observable<any> {
    return this.http.get(`${this.baseURL}/owner/${page}/${size}`);
  }

  createOwner(data): Observable<any> {
    return this.http.post(`${this.baseURL}/owner`, data);
  }

  updateOwner(data): Observable<any> {
    return this.http.put(`${this.baseURL}/owner/edit?ownerId=${data.id}`, data);
  }
  
  deleteOwner(ref): Observable<any> {
    return this.http.delete(`${this.baseURL}/owner/${ref}`);
  }

}
