import { ToastrService } from 'ngx-toastr';
import { AccountsService } from './../../shared/services/accounts.service';
import { StorageService } from './../../shared/services/storage.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.css']
})
export class ClientHomeComponent implements OnInit {

  @ViewChild('doughnutCanvas') doughnutCanvas: ElementRef;
  doughnutChart: any;

  accNum: string;
  dname: string;
  date: string;
  atype: string;
  cards: any;
  accInfo: any;

  constructor(
    private storageService : StorageService,
    private accountsService: AccountsService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    let dn = this.storageService.decrypt(sessionStorage.getItem('display'));
    let at = this.storageService.decrypt(sessionStorage.getItem('acc_type'));
    let ac = this.storageService.decrypt(sessionStorage.getItem('acc_number'));

    this.dname = dn ? dn : 'User';
    this.atype = at ? at : '';
    this.accNum = ac ? ac : '';

    this.date = new Date().toUTCString().slice(0, 16);

    this.getStats();

    this.atype == 'COMPANY' ? this.getAccount() : false;
  }

  getStats(){
    this.accountsService.getClientDashStats(this.accNum, this.atype).subscribe(
      data => {
        // console.log(data);
        this.cards = data;
        this.doughnutChartMethod();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  doughnutChartMethod() {
    let labs = [], dat = [], colors = [];

    this.cards.forEach(r => {
      labs.push(r.cardStatus);
      dat.push(r.totalCards);
      let clr = (r.cardStatus == 'Active') ? 'green' : (r.cardStatus == 'New') ? 'blue' : 'red';
      colors.push(clr);
    });

    this.doughnutChart = new Chart(this.doughnutCanvas.nativeElement, {
      type: 'doughnut',
      data: {
        labels: labs,
        datasets: [{
          label: 'Number of Accounts',
          data: dat,
          backgroundColor: colors
        }]
      }
    });
  }

  getAccount(){
    this.accountsService.getCompanyByAccNumber(this.accNum).subscribe(
      data => {
        // console.log(data);
        this.accInfo = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    );
  }

}
