<div class="az-content-left az-content-left-components" *ngIf="uType == 'admin'">
    <div class="component-item">
      
      <label>Users</label> <br>
      <nav class="nav flex-column">
        <a routerLink="/admin/users/all" routerLinkActive="active" class="nav-link">All Users</a>
        <!-- <a routerLink="/admin/users/managers" routerLinkActive="active" class="nav-link">Account Managers</a> -->
        <!-- <a routerLink="/admin/users/owners" routerLinkActive="active" class="nav-link">Owners</a> -->
      </nav>

      <br><br>

      <label>Others</label> <br>
      <nav class="nav flex-column">
        <!-- <a routerLink="/admin/users/roles" routerLinkActive="active" class="nav-link">Roles</a> -->
        <a routerLink="/admin/users/permissions" routerLinkActive="active" class="nav-link">Permissions</a>
        <a routerLink="/admin/users/audit-trails" routerLinkActive="active" class="nav-link">Audit Trails</a>
      </nav>

    </div><!-- component-item -->

</div>

<div class="az-content-left az-content-left-components" *ngIf="uType == 'client'">
  <div class="component-item">
    
    <label>Users</label> <br>
    <nav class="nav flex-column">
      <a routerLink="/admin/users/managers" routerLinkActive="active" class="nav-link">Account Managers</a>
    </nav>

    <br><br>

    <label>Others</label> <br>
    <nav class="nav flex-column">
    </nav>

  </div><!-- component-item -->

</div>