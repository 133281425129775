import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { SelfIndividualComponent } from './components/self-individual/self-individual.component';
import { SelfCompanyComponent } from './components/self-company/self-company.component';


@NgModule({
  declarations: [FooterComponent, HeaderComponent, SelfIndividualComponent, SelfCompanyComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SelfCompanyComponent,
    SelfIndividualComponent
  ]
})
export class SharedModule { }
