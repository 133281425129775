import { StationTransactionsComponent } from './station-transactions/station-transactions.component';
import { MngTerminalsComponent } from './mng-terminals/mng-terminals.component';
import { MngProductsComponent } from './mng-products/mng-products.component';
import { MngAttendantsComponent } from './mng-attendants/mng-attendants.component';
import { MngStationsComponent } from './mng-stations/mng-stations.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'all', pathMatch: 'full' },
  { path: 'all', component: MngStationsComponent },
  { path: 'attendants/:id', component: MngAttendantsComponent },
  { path: 'products/:id', component: MngProductsComponent },
  { path: 'terminals/:id', component: MngTerminalsComponent },
  { path: 'view-transactions/:id', component: StationTransactionsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminStationsRoutingModule { }
