import { StorageService } from './../../../shared/services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { StationService } from './../../../shared/services/station.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-products',
  templateUrl: './mng-products.component.html',
  styleUrls: ['./mng-products.component.css']
})
export class MngProductsComponent implements OnInit {

  stationId: number;
  products: any;
  selectedProduct: any;
  productId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private fb : FormBuilder,
    private toast: ToastrService,
    private stationService : StationService,
    private activateRoute : ActivatedRoute,
    private storageService : StorageService
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.stationId = params["id"];
    });
    this.getProducts();

    this.addForm = this.fb.group({
      productName: ['', Validators.required],
      price: ['', Validators.required],
      currency: ['USD', Validators.required],
      serviceStationId: [this.stationId, Validators.required],
    });

    this.editForm = this.fb.group({
      id : 0,
      productName: '',
      price: '',
      currency: '',
      serviceStationId: this.stationId,
    });
  }

  getProducts(){
    this.stationService.getPaginatedProductsByStation(0, 100, this.stationId).subscribe(
      data => {
        // console.table(data);

        this.products = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  addProduct(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.createProduct(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#add').modal('hide');
          this.getProducts();
          this.toast.success('Product Created Succesfully ');
          this.addForm = this.fb.group({
            productName: ['', Validators.required],
            price: ['', Validators.required],
            currency: ['USD', Validators.required],
            serviceStationId: [this.stationId, Validators.required],
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  selectProduct(ref){
    ref ? this.productId = ref : false; $('#delete').modal('show');
  }

  selectProduct2(ref){
    ref ? this.productId = ref : false; $('#price').modal('show');
  }

  changePrice(){
    let price = $('#newprice').val();

    if(price){
      let data = {amount: price, productId: this.productId };
      this.stationService.changeProductPrice(data).subscribe(
        resp => {
          this.toast.success('Price Changed Successfully');
          $('#price').modal('hide');
          this.getProducts();
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Enter New Price');
    }
  }

  deleteProduct(){
    if(this.productId){
      this.stationService.deleteProduct(this.productId).subscribe(
        resp => {
          // console.log(resp);
          $('#delete').modal('hide');
          this.getProducts();
          this.toast.success('Product Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  updateProduct(form: FormGroup){
    console.log(form.value);
    if(form.valid){
      this.stationService.updateProduct(form.value).subscribe(
        resp => {
          console.log(resp);
          $('#edit').modal('hide');
          this.getProducts();
          this.toast.success('Product Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  loadProduct(ref){
    if(ref){
      this.products.forEach(r => {
        (r.id == ref) ? this.selectedProduct = r : '';
      });
      
      if(this.selectedProduct){
        this.editForm = this.fb.group({
          id: [this.selectedProduct.id, Validators.required],
          productName: [this.selectedProduct.productName, Validators.required],
          price: [this.selectedProduct.price, Validators.required],
          currency: [this.selectedProduct.currency, Validators.required],
          serviceStationId: [this.stationId, Validators.required],
        });

        $('#edit').modal('show');
      }
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

}
