import { environment } from './../../../environments/environment';
import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  endpoint: string = environment.baseURL + '/akupay-oauth-service/api/v1';

  constructor(
    private http: HttpClient,
    private storageService : StorageService
  ) { }

  generalSignIn(data) : Observable<any> {
    return this.http.post<any>(`${this.endpoint}/login`, data);
  }

  newSignIn(data) : Observable<any> {
    return this.http.post<any>(`${this.endpoint}/client-login`, data);
  }

  getToken() {
    return this.storageService.decrypt(sessionStorage.getItem('access_token'));
  }

  getUserDisplayName() {
    return this.storageService.decrypt(sessionStorage.getItem('display'));
  }

  getUserName() {
    return this.storageService.decrypt(sessionStorage.getItem('user'));
  }

  getUserType() {
    return this.storageService.decrypt(sessionStorage.getItem('user_type'));
  }

  get isLoggedIn(): boolean {
    // return true;

    let authToken = sessionStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  doLogout(): boolean {

    sessionStorage.clear();

    return true;
  }

}
