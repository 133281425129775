import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from './../../services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-self-individual',
  templateUrl: './self-individual.component.html',
  styleUrls: ['./self-individual.component.css']
})
export class SelfIndividualComponent implements OnInit {

  addForm: FormGroup;
  mobErr: string;
  agreed: boolean = true;

  constructor(
    private toast: ToastrService,
    private accountsService: AccountsService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.addForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      idNumber: ['', Validators.required],
      organisationId: [2, Validators.required],
    });
  }

  selfRegister(form: FormGroup){
    // console.log(form.value);
    if(!this.validateNumber(form.value.mobile)){
      this.mobErr = 'Invalid Mobile Pattern';
      return false;
    }
    else{
      this.mobErr = null;
    }

    if(form.valid){
      this.accountsService.individualSelfReg(form.value).subscribe(
        data => {
          // console.table(data);
          this.toast.success('Individual Account Application Sent.');
          this.router.navigate(['/login']);
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      )
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

  aggreeToTerms(){
    let val = $('#agree').is(':checked');
    
    // val ? $('#btnSubmit').attr('disabled', false) : $('#btnSubmit').attr('disabled', false);

    this.agreed = !val;
    
  }

  validateNumber(number) {
    const re = /^2637[13478][0-9]{7}$/;
    // const re = /^(263)7[7-8|1|3][0-9]{7}$/;
    return re.test(number);
  }

}