import { UsersService } from 'src/app/shared/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mng-permissions',
  templateUrl: './mng-permissions.component.html',
  styleUrls: ['./mng-permissions.component.css']
})
export class MngPermissionsComponent implements OnInit {

  roles: any;
  selectedRole: number;
  assPerms: any;
  unAssPerms: any;

  addForm: FormGroup;
  removeForm: FormGroup;

  constructor(
    private fb : FormBuilder,
    private toast : ToastrService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.getRoles();   
    
    this.addForm = this.fb.group({
      authoritiesId: [
        0
      ],
      roleId: 0
    });
    this.removeForm = this.fb.group({
      authoritiesId: [
        0
      ],
      roleId: 0
    });
  }

  getRoles(){
    this.usersService.getAllRoles()
    .subscribe(
      data => {
        // console.log(data);
        this.roles = data;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  selectRole(rid){
    if(rid){
      this.selectedRole = rid;

      this.addForm = this.fb.group({
        authoritiesId: [
          0
        ],
        roleId: rid
      });
      this.removeForm = this.fb.group({
        authoritiesId: [
          0
        ],
        roleId: rid
      });

      this.getAssPerms();
      this.getUnAssPerms();
    }
  }

  getAssPerms(){
    this.usersService.getAssignedPermissions(this.selectedRole)
    .subscribe(
      data => {
        // console.log(data);
        this.assPerms = data;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  getUnAssPerms(){
    this.usersService.getUnassignedPermissions(this.selectedRole)
    .subscribe(
      data => {
        // console.log(data);
        this.unAssPerms = data;
      },
      err => {
        console.log(err);
        this.toast.error('An Error Occured. Please Try Again.!!');
      }
    )
  }

  addPermission(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.usersService.assignPermission(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success('Permission Added.');
          this.getAssPerms();
          this.getUnAssPerms();
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      )
    }
  }

  removePermission(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.usersService.unassignPermission(form.value)
      .subscribe(
        resp => {
          // console.log(resp);
          this.toast.success('Permission Removed.');
          this.getAssPerms();
          this.getUnAssPerms();
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      )
    }
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

}
