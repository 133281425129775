import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-self-reg',
  templateUrl: './self-reg.component.html',
  styleUrls: ['./self-reg.component.css']
})
export class SelfRegComponent implements OnInit {

  selected: string = '';

  constructor(
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
  }

  getSelected(){
    let val = $('#accType').val();

    if(val){
      this.selected = val;
    }
    else{
      this.toast.warning('Choose account type.');
    }
  }

}
