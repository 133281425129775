import { UsersService } from './../../shared/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  token: string;

  changeForm: FormGroup

  constructor(
    private activeRoute: ActivatedRoute,
    private toast: ToastrService,
    private userService: UsersService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(
      params => this.token = params['token']
    );

    this.changeForm = this.fb.group({
      token: [this.token, Validators.required],
      newPassword: ['', Validators.required]
    });
  }

  sendToken(){
    let email = $('#myEmail').val();

    if(email){
      this.userService.resetUser(email).subscribe(
        resp => {
          // console.log(resp);
          this.toast.success(resp.message);
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Enter email.');
    }
  }

  changePassword(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.userService.resetUserPassword(form.value).subscribe(
        resp => {
          // console.log(resp);
          this.toast.success(resp.message ? resp.message : 'Password Changed');
          this.router.navigate(['/login']);
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Enter Password.');
    }
  }

}
