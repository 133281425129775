<div class="az-header" *ngIf="userType == 'admin'">
  <div class="container">
    <div class="az-header-left w-25">
      <a routerLink="/admin/home" class="d-none d-lg-block">
        <img src="/assets/img/thuli.png" class="w-75" alt="Thuli Logo">
      </a>
      <a href="" id="azMenuShow" class="az-header-menu-icon d-lg-none"
        ><span></span
      ></a>
    </div>
    <!-- az-header-left -->
    <div class="az-header-menu">
      <div class="az-header-menu-header">
        <a routerLink="/admin/home" class="az-logo text-center">
          <img src="/assets/img/thuli-mini.png" class="w-40" alt="Mini Logo">
        </a>
        <a href="" class="close">&times;</a>
      </div>
      <!-- az-header-menu-header -->
      <ul class="nav">
        <li class="nav-item {{ (isSelected == 'home') ? 'active' : '' }}" (click)="setSelection('home')">
          <a routerLink="/admin/home" class="nav-link"
            ><i class="typcn typcn-chart-area-outline"></i> Dashboard</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'stations') ? 'active' : '' }}" (click)="setSelection('stations')">
          <a routerLink="/admin/stations" class="nav-link"
            ><i class="typcn typcn-home-outline"></i> Stations</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'accounts') ? 'active' : '' }}" (click)="setSelection('accounts')">
          <a routerLink="/admin/accounts" class="nav-link"
            ><i class="typcn typcn-contacts"></i> Accounts</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'transactions') ? 'active' : '' }}" (click)="setSelection('transactions')">
          <a routerLink="/admin/transactions" class="nav-link"
            ><i class="typcn typcn-chart-bar-outline"></i> Transactions</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'users') ? 'active' : '' }}" (click)="setSelection('users')">
          <a routerLink="/admin/users" class="nav-link"
            ><i class="typcn typcn-user-add-outline"></i> Users</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'settings') ? 'active' : '' }}" (click)="setSelection('settings')">
          <a routerLink="/admin/settings" class="nav-link"
            ><i class="typcn typcn-cog-outline"></i> Settings</a
          >
        </li>
      </ul>
    </div>
    <!-- az-header-menu -->
    <div class="az-header-right">
      <!-- az-header-message -->
      <!-- <div class="dropdown az-header-notification">
        <a href="#" class="new"><i class="typcn typcn-bell"></i></a>
        <div class="dropdown-menu">
          <div class="az-dropdown-header mg-b-20 d-sm-none">
            <a href="" class="az-header-arrow"
              ><i class="icon ion-md-arrow-back"></i
            ></a>
          </div>
          <h6 class="az-notification-title">Notifications</h6>
          <p class="az-notification-text">You have 2 unread notification</p>
          <div class="az-notification-list">
            <div class="media new">
              <div class="az-img-user">
                <img src="assets/img/faces/face2.jpg" alt="" />
              </div>
              <div class="media-body">
                <p>
                  Congratulate <strong>Socrates Itumay</strong> for work
                  anniversaries
                </p>
                <span>Mar 15 12:32pm</span>
              </div>
            </div>
            <div class="media">
              <div class="az-img-user">
                <img src="assets/img/faces/face5.jpg" alt="" />
              </div>
              <div class="media-body">
                <p>
                  <strong>Adrian Monino</strong> added new comment on your photo
                </p>
                <span>Mar 12 10:40pm</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- az-header-notification -->
      <div class="dropdown az-profile-menu">
        <!-- <a class="az-img-user pointer"
          ><img src="assets/img/faces/face1.jpg" alt=""
        /></a> -->
        <a class="pointer"><span class="h5">{{dname}}</span></a>
        <div class="dropdown-menu">
          <div class="az-dropdown-header d-sm-none">
            <a href="" class="az-header-arrow"
              ><i class="icon ion-md-arrow-back"></i
            ></a>
          </div>
          <div class="az-header-profile">
            <div class="az-img-user">
              <img src="assets/img/faces/face1.jpg" alt="" />
            </div>
            <!-- az-img-user -->
            <h6>{{uname}}</h6>
            <span>{{dname}}</span>
          </div>
          <!-- az-header-profile -->

          <!-- <a href="#" class="dropdown-item"
            ><i class="typcn typcn-user-outline"></i> My Profile</a
          > -->
          <a routerLink="/admin/change-password" class="dropdown-item"
            ><i class="typcn typcn-edit"></i> Change Password</a
          >
          <!-- <a href="#" class="dropdown-item"
            ><i class="typcn typcn-cog-outline"></i> Account Settings</a
          > -->
          <a (click)="doLogout()" class="dropdown-item pointer"
            ><i class="typcn typcn-power-outline"></i> Sign Out</a
          >
        </div>
        <!-- dropdown-menu -->
      </div>
    </div>
    <!-- az-header-right -->
  </div>
  <!-- container -->
</div>

<div class="az-header" *ngIf="userType == 'client'">
  <div class="container">
    <div class="az-header-left w-25">
      <a routerLink="/client/home" class="d-none d-lg-block">
        <img src="/assets/img/thuli.png" class="w-75" alt="Thuli Logo">
      </a>
      <a  id="azMenuShow" class="az-header-menu-icon d-lg-none pointer"
        ><span></span
      ></a>
    </div>
    <!-- az-header-left -->
    <div class="az-header-menu">
      <div class="az-header-menu-header">
        <a routerLink="/client/home" class="">
          <img src="/assets/img/thuli.png" class="w-75" alt="Thuli Logo">
        </a>
        <a href="" class="close">&times;</a>
      </div>
      <!-- az-header-menu-header -->
      <ul class="nav">
        <li class="nav-item {{ (isSelected == 'home') ? 'active' : '' }}" (click)="setSelection('home')">
          <a routerLink="/client/home" class="nav-link"
            ><i class="typcn typcn-chart-area-outline"></i> Dashboard</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'accounts') ? 'active' : '' }}" (click)="setSelection('accounts')">
          <a routerLink="/client/accounts" class="nav-link"
            ><i class="typcn typcn-contacts"></i> Account</a
          >
        </li>
        <li class="nav-item {{ (isSelected == 'transactions') ? 'active' : '' }}" (click)="setSelection('transactions')">
          <a routerLink="/client/transactions" class="nav-link"
            ><i class="typcn typcn-chart-bar-outline"></i> Transactions</a
          >
        </li>
        <!-- <li *ngIf="accType == 'COMPANY'" class="nav-item {{ (isSelected == 'users') ? 'active' : '' }}" (click)="setSelection('users')">
          <a routerLink="/client/users" class="nav-link"
            ><i class="typcn typcn-user-add-outline"></i> Users</a
          >
        </li> -->
      </ul>
    </div>
    <!-- az-header-menu -->
    <div class="az-header-right">
      <!-- az-header-message -->
      <!-- az-header-notification -->
      <div class="dropdown az-profile-menu">
        <!-- <a class="az-img-user pointer"><img src="assets/img/faces/face1.jpg" alt=""/></a> -->
        <a class="pointer"><span class="h5">{{dname}}</span></a>
        <div class="dropdown-menu">
          <div class="az-dropdown-header d-sm-none">
            <a href="" class="az-header-arrow"
              ><i class="icon ion-md-arrow-back"></i
            ></a>
          </div>
          <div class="az-header-profile">
            <div class="az-img-user">
              <img src="assets/img/faces/face1.jpg" alt="" />
            </div>
            <!-- az-img-user -->
            <h6>{{uname}}</h6>
            <span>{{dname}}</span>
          </div>
          <!-- az-header-profile -->

          <!-- <a href="#" class="dropdown-item"
            ><i class="typcn typcn-user-outline"></i> My Profile</a
          > -->
          <a routerLink="/client/accounts/change-password" class="dropdown-item"
            ><i class="typcn typcn-edit"></i> Change Password</a
          >
          <!-- <a href="#" class="dropdown-item"
            ><i class="typcn typcn-cog-outline"></i> Account Settings</a
          > -->
          <a (click)="doLogout()" class="dropdown-item pointer"
            ><i class="typcn typcn-power-outline"></i> Sign Out</a
          >
        </div>
        <!-- dropdown-menu -->
      </div>
    </div>
    <!-- az-header-right -->
  </div>
  <!-- container -->
</div>

