import { OwnerService } from './../../../shared/services/owner.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';
declare var $: any;

@Component({
  selector: 'app-owner-stngs',
  templateUrl: './owner-stngs.component.html',
  styleUrls: ['./owner-stngs.component.css']
})
export class OwnerStngsComponent implements OnInit {

  owners: any;

  addForm: FormGroup;

  constructor(
    private toast : ToastrService,
    private fb : FormBuilder,
    private ownerService : OwnerService
  ) { }

  ngOnInit(): void {
    this.getOwners();

    this.addForm = this.fb.group({
      organisation: ['', Validators.required]
    });
  }

  getOwners(){
    this.ownerService.getPaginatedOwners(0, 100).subscribe(
      data => {
        // console.table(data);

        this.owners = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  addOwner(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.ownerService.createOwner(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#add').modal('hide');
          this.getOwners();
          this.toast.success('Owner Created Succesfully ');
          this.addForm.reset;
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

}
