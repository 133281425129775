<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-users></app-sidebar-users>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column w-100">
        <div class="az-content-breadcrumb">
          <span>Users</span>
          <span>Audit Trails</span>
        </div>
        <h2 class="az-content-title">Audit Trails</h2>

        <div class="container d-block">
          <div class="row">
            <div class="col-md-8">
              <div class="az-content-label mg-b-5">All Audit Trails</div>
              <p class="mg-b-20">List of all system trails.</p>
            </div>
            <div class="col-md-4 mt-md-0 mt-3" align="right">
              <!-- <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
                NEW &nbsp;<i class="fa fa-plus-circle"></i>
              </button> -->
            </div>
          </div>
  
          <div class="row mt-4">
            <div class="table-responsive mt-4">
                <table class="table table-hover mg-b-0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Username</th>
                            <th>Resource</th>
                            <th>Action Perfomed</th>
                            <th>Client IP</th>
                          </tr>
                    </thead>
                    <tbody *ngIf="trails">
                        <tr *ngFor="let r of trails | paginate: config;">
                            <td>{{ r.id }}</td>
                            <td>{{ r.dateTime | date: 'medium' }}</td>
                            <td>{{ r.username }}</td>
                            <td>{{ r.resource }}</td>
                            <td>{{ r.actionPerformed }}</td>
                            <td>{{ r.clientIpAddress }}</td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <span *ngIf="trails" id="entriesCounter" class="text-secondary float-right">Showing {{ (page - 1) * pageSize + 1 }} - {{ showin }} of {{ config.totalItems }}.</span>
                <br>
                <div *ngIf="trails">
                    <div class="d-flex" id="controlsArea">
                        Items:
                        <select class="pageItems" (change)="handlePageSizeChange($event)">
                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                {{ size }}
                            </option>
                        </select>
                        <pagination-controls
                        class=""
                        responsive="true"
                        (pageChange)="handlePageChange($event)"
                        ></pagination-controls> 
                    </div> 
                </div>
              </div><!-- table-responsive -->            
          </div>
        </div>

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->