<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
      <div class="az-content-left az-content-left-components">
        <div class="component-item">
          
          <label>Accounts</label> <br>
          <nav class="nav flex-column">
            <a routerLink="/admin/accounts/individual" routerLinkActive="active" class="nav-link">Individual</a>
            <a routerLink="/admin/accounts/company" routerLinkActive="active" class="nav-link">Company</a>
          </nav>
    
          <br><br>
    
          <label>Others</label> <br>
          <nav class="nav flex-column">
            <a routerLink="/admin/accounts/cards" routerLinkActive="active" class="nav-link disabled">Cards</a>
            <a routerLink="/admin/accounts/cars" routerLinkActive="active" class="nav-link disabled">Vehicles</a>
          </nav>
    
        </div><!-- component-item -->
    
      </div>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column w-100">
        <div class="az-content-breadcrumb">
          <span>Accounts</span>
          <span>Company</span>
          <span>Managers</span>
        </div>
        <h2 class="az-content-title">Company Account Managers</h2>

        <div class="container d-block">
          <div class="row">
            <div class="col-md-8">
              <div class="az-content-label mg-b-5">All Managers List</div>
              <p class="mg-b-20">List of all Account Managers.</p>
            </div>
            <div class="col-md-4 mt-md-0 mt-3" align="right">
              <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
                NEW &nbsp;<i class="fa fa-plus-circle"></i>
              </button>
            </div>
          </div>
  
          <div class="row">
            <div class="table-responsive mt-4">
              <table class="table table-hover mg-b-0">
                <thead>
                  <tr>
                    <th>Reference</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Company</th>
                    <th>Account Number</th>
                    <th>Mobile</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let r of users;">
                    <td>{{r.id}}</td>
                    <td>{{r.firstname}} {{r.surname}}</td>
                    <td>{{r.email}}</td>
                    <td>{{r.companyAccount.companyName}}</td>
                    <td>{{r.companyAccount.accountNumber}}</td>
                    <td>{{r.mobile}}</td>
                     <td>
                      <span class="pointer text-primary ml-1" title="Update Manager" (click)="loadManager(r.id)">
                        <i class="fa fa-edit"></i>
                      </span>
                      <span class="pointer text-danger ml-1" title="Delete Manager" (click)="selectManager(r.id)">
                        <i class="fa fa-trash-alt"></i>
                      </span>
                  </tr>
                </tbody>
              </table>
            </div><!-- table-responsive -->
          </div>
        </div>

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE MANAGER</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addManager(addForm)">
            <!-- <div class="form-group">
              <label>Company</label>
              <select formControlName="companyId" required class="form-control">
                <option value="" selected disabled>choose company...</option>
                <option *ngFor="let c of companies;" value="{{c.id}}">{{c.companyName}}</option>
              </select>
            </div> -->
            <div class="form-group">
              <label>Firstname</label>
              <input formControlName="firstName" required type="text" class="form-control" placeholder="Enter your firstname">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Lastname</label>
              <input formControlName="lastName" required type="text" class="form-control" placeholder="Enter your lastname">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Email</label>
              <input formControlName="email" required type="text" class="form-control" placeholder="Enter your email">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Mobile</label> &nbsp;&nbsp; <em class="text-muted font-smaller">263xxxxxxxxx</em>
              <input formControlName="mobile" required type="text" class="form-control" placeholder="Enter your mobile">
            </div><!-- form-group -->
            <div class="form-group">
              <label>Role</label>
              <select formControlName="role" required class="form-control">
                <option value="" selected disabled>choose...</option>
                <option value="MAKER">Maker</option>
                <option value="CHECKER">Checker</option>
                <option value="MAKER_CHECKER">Maker & Checker</option>
              </select>
            </div><!-- form-group -->
            <div class="form-group"></div><!-- form-group -->
            <button type="submit" class="btn btn-az-primary btn-block">Create Manager</button>
            <div class="row row-xs"></div><!-- row -->
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE MANAGER</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateManager(editForm)">
            <div class="container">
              <div class="form-group">
                <label>Firstname</label>
                <input formControlName="firstName" required type="text" class="form-control" placeholder="Enter your firstname">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Lastname</label>
                <input formControlName="lastName" required type="text" class="form-control" placeholder="Enter your lastname">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Email</label>
                <input formControlName="email" required type="text" class="form-control" placeholder="Enter your email">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Mobile</label>
                <input formControlName="mobile" required type="text" class="form-control" placeholder="Enter your mobile">
              </div><!-- form-group -->
              <div class="form-group">
                <label>Role</label>
                <select formControlName="role" required class="form-control">
                  <option value="" selected disabled>choose role...</option>
                  <option value="MAKER">Maker</option>
                  <option value="CHECKER">Checker</option>
                  <option value="MAKER_CHECKER">Maker & Checker</option>
                </select>
              </div><!-- form-group -->
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Manager</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE Manager</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete a Manager!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" (click)="deleteManager()" type="button"><i class="fa fa-trash-alt"></i>&nbsp; Delete Manager</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>