import { ToastrService } from 'ngx-toastr';
import { AuditTrailsService } from './../../../shared/services/audit-trails.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mng-trails',
  templateUrl: './mng-trails.component.html',
  styleUrls: ['./mng-trails.component.css']
})
export class MngTrailsComponent implements OnInit {

  trails: any;

  config: any;
  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  totalItems = 0;
  showin = 0;

  constructor(
    private trailsService: AuditTrailsService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getAllTrails();
  }

  handlePageChange(event) {
    this.page = event;
    this.getAllTrails();
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllTrails();
  }

  getAllTrails(){
    this.trailsService.getPaginatedTrails(this.page - 1,this.pageSize).subscribe(
      data => {
        // console.log(data);
        this.config = {
          itemsPerPage: this.pageSize,
          currentPage: data.pageable.pageNumber + 1,
          totalItems: data.totalElements,
          totalPages: data.totalPages
        };   

        if(this.page * this.pageSize > data.totalElements){
          this.showin = data.totalElements;
        }
        else{
          this.showin = this.page * this.pageSize;
        }
        
        this.trails = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    );
  }

}
