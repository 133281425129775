import { ActivatedRoute } from '@angular/router';
import { AccountsService } from './../../../shared/services/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-vehicle',
  templateUrl: './mng-vehicle.component.html',
  styleUrls: ['./mng-vehicle.component.css']
})
export class MngVehicleComponent implements OnInit {

  cars: any;
  selectedCar: any;
  carId: number;

  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private fb : FormBuilder,
    private toast : ToastrService,
    private accountsService : AccountsService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activeRoute.params.subscribe((params) => {
      this.carId = params["id"];
    });

    this.getCars();

    this.addForm = this.fb.group({
      model: ['', Validators.required],
      registrationNumber: ['', Validators.required],
      make: ['', Validators.required],
      fuelType: ['', Validators.required],
      cardId: [this.carId, Validators.required],
    });

    this.editForm = this.fb.group({
      id : 0,
      model: '',
      make: '',
      registrationNumber: '',
      cardId: 1,
      fuelType: ''
    });
  }

  getCars(){
    this.accountsService.getCardCars(this.carId).subscribe(
      data => {
        console.table(data);

        this.cars = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  addCar(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.accountsService.createCar(form.value).subscribe(
        resp => {
          console.log(resp);
          $('#add').modal('hide');
          this.getCars();
          this.toast.success('Car Created Succesfully ');
          this.addForm = this.fb.group({
            model: ['', Validators.required],
            registrationNumber: ['', Validators.required],
            make: ['', Validators.required],
            fuelType: ['', Validators.required],
            cardId: [this.carId, Validators.required],
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  selectCar(ref){
    ref ? this.carId = ref : false; $('#delete').modal('show');
  }

  deleteCar(){
    if(this.carId){
      this.accountsService.deleteCar(this.carId).subscribe(
        resp => {
          console.log(resp);
          $('#delete').modal('hide');
          this.getCars();
          this.toast.success('Car Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  updateCar(form: FormGroup){
    console.log(form.value);
    if(form.valid){
      this.accountsService.updateCar(form.value).subscribe(
        resp => {
          console.log(resp);
          $('#edit').modal('hide');
          this.getCars();
          this.toast.success('Car Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  loadCar(ref){
    if(ref){
      this.cars.forEach(r => {
        (r.id == ref) ? this.selectedCar = r : '';
      });
      
      if(this.selectedCar){
        this.editForm = this.fb.group({
          carId: [this.selectedCar.id, Validators.required],
          model: [this.selectedCar.model, Validators.required],
          registrationNumber: [this.selectedCar.registrationNumber, Validators.required],
          make: [this.selectedCar.make, Validators.required],
          fuelType: [this.selectedCar.fuelType, Validators.required],
          // cardId: [this.selectedCar.cardId, Validators.required],
        });

        $('#edit').modal('show');
      }
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

}
