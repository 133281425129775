import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailsService {

  baseUrl: string = environment.baseURL + '/akupay-audit-trail-service/api/v1/audits';

  constructor(
    private http: HttpClient
  ) { }

  getPaginatedTrails(page, size): Observable<any> {
    return this.http.get(this.baseUrl + '/all/' + page + '/' + size);
  }

  getManipulatedPaginatedTrails(page, size, param, search): Observable<any> {

    if(param && search){
      return this.http.get(this.baseUrl + '/date-range/' + page + '/' + size + '?' + search + '&' + param);
    }
    else if(param && !search){
      return this.http.get(this.baseUrl + '/date-range/' + page + '/' + size + '?' + param);
    }
    else if(!param && search){
      return this.http.get(this.baseUrl + '/all/' + page + '/' + size + '?' + search);
    }
    else{
      return this.http.get(this.baseUrl + '/all/' + page + '/' + size);
    }
    
  }

  exportAuditTrails(params, query): Observable<any> {
    if(query){
      return this.http.get(this.baseUrl + '/generate-audit-report?' + query + '&' + params, {responseType: 'blob'});
    }

    return this.http.get(this.baseUrl + '/generate-audit-report?' + params, {responseType: 'blob'});
  }

}
