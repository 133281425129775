<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-stations></app-sidebar-stations>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Stations</span>
          <span>Station</span>
          <span>Products</span>
        </div>
        <h2 class="az-content-title">Service Station Products</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">All Products List</div>
            <p class="mg-b-20">List of all registered Station Products.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button>
          </div>
        </div>

        <div class="table-responsive mt-4">
          <table class="table table-hover mg-b-0">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Price</th>
                <th>Currency</th>
                <th>Station</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of products;">
                <th>{{r.id}}</th>
                <td>{{r.productName}}</td>
                <td>
                  {{r.price}}
                  <span class="text-primary pointer ml-2" title="Update Price" (click)="selectProduct2(r.id)">
                    <i class="fa fa-pen"></i>
                  </span>
                </td>
                <td>{{r.currency}}</td>
                <th>{{r.serviceStation.stationName}}</th>
                <td>
                  <span class="text-primary pointer" title="Update Record" (click)="loadProduct(r.id)">
                    <i class="fa fa-edit"></i>
                  </span>
                  <span class="text-danger pointer ml-2" title="Delete Record" (click)="selectProduct(r.id)">
                    <i class="fa fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div><!-- table-responsive -->

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->

<!-- Modals -->
<div class="modal fade" id="add" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CREATE PRODUCT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="addForm" (ngSubmit)="addProduct(addForm)">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    Name
                    <input type="text" formControlName="productName" placeholder="Product Name" required class="form-control">
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                      Price
                      <input type="text" formControlName="price" placeholder="Price" required class="form-control">
                    </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    Currency
                    <input type="text" readonly formControlName="currency" placeholder="Currency" required class="form-control">
                  </div>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Add Product</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">UPDATE PRODUCT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <form [formGroup]="editForm" (ngSubmit)="updateProduct(editForm)">
            <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      Name
                      <input type="text" formControlName="productName" placeholder="Product Name" required class="form-control">
                    </div>
                  </div>
                  <div class="col-md-12">
                      <div class="form-group">
                        Price
                        <input type="text" formControlName="price" placeholder="Price" required class="form-control">
                      </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      Currency
                      <input type="text" formControlName="currency" placeholder="Currency" required class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Update Product</button>
            </div>
          </form>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="delete" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">DELETE PRODUCT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>You are about to delete a Product!</h2>
                </div>
              </div>
            </div>
            <br><br>
            <div align="center">
              <button class="btn btn-sm btn-rounded btn-outline-primary" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
              <button class="ml-2 btn btn-rounded btn-sm btn-outline-danger" type="button" (click)="deleteProduct()"><i class="fa fa-trash-alt"></i>&nbsp; Delete Product</button>
            </div>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="price" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center w-100" id="">CHANGE PRODUCT PRICE</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <section>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                    New Price
                    <input type="text" id="newprice" placeholder="Price" required class="form-control">
                  </div>
              </div>
            </div>
          </div>
          <br><br>
          <div align="center">
            <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
            <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="button" (click)="changePrice()"><i class="fa fa-save"></i>&nbsp; Change Price</button>
          </div>
        </section>
      </div>      
    </div>
  </div>
</div>