import { StorageService } from './../../../shared/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StationService } from './../../../shared/services/station.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-terminals',
  templateUrl: './mng-terminals.component.html',
  styleUrls: ['./mng-terminals.component.css']
})
export class MngTerminalsComponent implements OnInit {

  stationId: number;
  terminals: any;
  selectedTerminal: any;
  terminalId: number;
  stations: any;

  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private fb : FormBuilder,
    private toast : ToastrService,
    private stationService : StationService,
    private activateRoute : ActivatedRoute,
    private storageService : StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.stationId = params["id"];
    });

    this.getTerminals();

    this.addForm = this.fb.group({
      imei: ['', Validators.required],
      terminalId: ['', Validators.required],
      terminalType: ['', Validators.required],
      serviceStationId: [this.stationId, Validators.required]
    });

    this.editForm = this.fb.group({
      id : 0,
      imei: '',
      terminalType: '',
      terminalId: '',
      serviceStationId: '',
    });

    this.getStations();
  }

  getTerminals(){
    this.stationService.getPaginatedTerminalsByStation(0, 100, this.stationId).subscribe(
      data => {
        // console.table(data);

        this.terminals = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  addTerminal(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.createTerminal(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#add').modal('hide');
          this.getTerminals();
          this.toast.success('Terminal Created Succesfully ');
          this.addForm = this.fb.group({
            imei: ['', Validators.required],
            terminalId: ['', Validators.required],
            terminalType: ['', Validators.required],
            serviceStationId: [this.stationId, Validators.required]
          });
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  selectTerminal(ref){
    ref ? this.terminalId = ref : false; $('#delete').modal('show');
  }

  deleteTerminal(){
    if(this.terminalId){
      this.stationService.deleteTerminal(this.terminalId).subscribe(
        resp => {
          // console.log(resp);
          $('#delete').modal('hide');
          this.getTerminals();
          this.toast.success('Terminal Deleted Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  updateTerminal(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.stationService.updateTerminal(form.value).subscribe(
        resp => {
          // console.log(resp);
          $('#edit').modal('hide');
          this.getTerminals();
          this.toast.success('Terminal Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  loadTerminal(ref, typ){
    if(ref){
      this.terminals.forEach(r => {
        (r.id == ref) ? this.selectedTerminal = r : '';
      });
      
      if(this.selectedTerminal){
        this.editForm = this.fb.group({
          id: [this.selectedTerminal.id],
          terminalId: [this.selectedTerminal.terminalId, Validators.required],
          imei: [this.selectedTerminal.imei, Validators.required],
          terminalType: [this.selectedTerminal.terminalType, Validators.required]
        });

        this.terminalId = ref;

        (typ == 'edit') ? $('#edit').modal('show') : $('#transfer').modal('show');
      }
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  transferTerminal(){
    let data = { 'serviceStationId': $('#serviceStationId').val(), terminalId: this.terminalId };
    // console.log(data);
    if(data){
      this.stationService.transferTerminal(data, this.terminalId).subscribe(
        resp => {
          // console.log(resp);
          $('#transfer').modal('hide');
          this.getTerminals();
          this.toast.success('Terminal Transferred Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }    
    else{
      this.toast.warning('Missing Required Fields.');
    }
  }

  getStations(){
    this.stationService.getPaginatedStations(0, 100).subscribe(
      data => {
        // console.table(data);

        this.stations = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  checkStation(ref){
    return (ref == this.stationId);
  }

  activateTerminal(ref, status: boolean){
    if(ref){
      this.stationService.terminalStatus(ref, status).subscribe(
        resp => {
          // console.log(resp);
          // $('#delete').modal('hide');
          this.getTerminals();
          this.toast.success('Status Changed Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('No Reference Found.');
    }
  }

  viewTransactions(ref, name){
    sessionStorage.setItem('trans-mode', this.storageService.encrypt('terminal'));
    sessionStorage.setItem('trans-disp', this.storageService.encrypt(ref));

    this.router.navigate(['/admin/stations/view-transactions/' + name]);
  }

}
