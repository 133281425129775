<div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
        <app-sidebar-settings></app-sidebar-settings>
        <!-- az-content-left -->
      
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <div class="az-content-breadcrumb">
          <span>Settings</span>
          <span>Change Password</span>
        </div>
        <h2 class="az-content-title"> Change Password</h2>

        <div class="row">
          <div class="col-md-8">
            <div class="az-content-label mg-b-5">User Change Password</div>
            <p class="mg-b-20">Change my login password.</p>
          </div>
          <div class="col-md-4 mt-md-0 mt-3" align="right">
            <!-- <button class="btn btn-outline-success btn-rounded btn-sm" data-toggle="modal" data-target="#add">
              NEW &nbsp;<i class="fa fa-plus-circle"></i>
            </button> -->
          </div>
        </div>

        <div class="mt-4">
            <div align="center">
                <section class="w-50 text-left">
                    <form [formGroup]="addForm" (ngSubmit)="changePassword(addForm)">
                        <div class="container">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  Old Password
                                  <input type="password" formControlName="oldPassword" placeholder="Old Password" required class="form-control">
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  New Password
                                  <input type="password" formControlName="newPassword" placeholder="New Password" required class="form-control">
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  Confirm Password
                                  <input type="password" formControlName="matchingPassword" placeholder="Confirm Password" required class="form-control">
                                </div>
                              </div>
                            </div>
                        </div>
                        <br><br>
                        <div align="center">
                            <button class="btn btn-sm btn-rounded btn-outline-danger" data-dismiss="modal" type="button"><i class="fa fa-times"></i>&nbsp; Cancel</button>
                            <button class="ml-2 btn btn-rounded btn-sm btn-outline-primary" type="submit"><i class="fa fa-save"></i>&nbsp; Change Password</button>
                        </div>
                    </form>
                </section>     
            </div>
        </div>

        <div class="ht-40"></div>

        
      </div><!-- az-content-body -->
    
    </div><!-- container -->
</div><!-- az-content -->