import { ActivatedRoute } from '@angular/router';
import { AccountsService } from './../../../shared/services/accounts.service';
import { UsersService } from './../../../shared/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-mng-managers',
  templateUrl: './mng-managers.component.html',
  styleUrls: ['./mng-managers.component.css']
})
export class MngManagersComponent implements OnInit {

  users: any;
  roles: any;
  companies: any;
  ref: number;

  selectedUser: any;

  addForm: FormGroup;
  editForm: FormGroup;

  constructor(
    private toast : ToastrService,
    private userService : UsersService,
    private accountsService : AccountsService,
    private fb : FormBuilder,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activeRoute.params.subscribe((params) => {
      this.ref = params["id"];
    });

    this.getManagers();

    // this.getCompanies();

    this.editForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      role: ['', Validators.required],
      accountManagerId: ['']
    });

    this.formInit();
  }

  getRoles(){
    this.userService.getAllRoles().subscribe(
      data => {
        // console.table(data);

        this.roles = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getCompanies(){
    this.accountsService.getPaginatedCompany(0, 100).subscribe(
      data => {
        // console.table(data);

        this.companies = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        } else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  getManagers(){
    this.accountsService.getPaginatedCompanyManagers(0, 100, this.ref).subscribe(
      data => {
        // console.table(data);

        this.users = data.content;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  updateManager(form: FormGroup){
    // console.log(form.value);
    
    if(form.valid){
      this.accountsService.updateManager(form.value).subscribe(
        resp => {
          // console.log(resp);

          $('#edit').modal('hide');
          this.getManagers();
          this.toast.success('Manager Updated Succesfully ');
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning("Missing Required Info");
    }
  }

  loadManager(ref){
    let user;
    
    if(ref){
      this.users.forEach(r => {
        if(r.id == ref) { user = r };
       });

       console.log(user);
       
       if(user){
        this.editForm = this.fb.group({
          id: [user.id, Validators.required],
          email: [user.email, Validators.required],
          firstName: [user.firstname, Validators.required],
          lastName: [user.surname, Validators.required],
          mobile: [user.mobile, Validators.required],
          role: [user.role, Validators.required],
          accountManagerId: [user.id]
        });

        $('#edit').modal('show');
       }
    }
    else{
      this.toast.warning("Reference not Found.");
    }
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

  selectManager(ref){
    if(ref){
      this.selectedUser = ref;
      $('#delete').modal('show');
    }
  }

  deleteManager(){
    this.selectedUser ?
    this.accountsService.deleteManager(this.selectedUser).subscribe(
      resp => {
        console.log(resp);
        this.toast.success('Manager Deleted Successfully');
        $('#delete').modal('hide');
        this.getManagers();
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
          else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    ) :
    this.toast.warning('No Reference found.');

  }

  formInit(){
    this.addForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      role: ['', Validators.required],
      companyId: [this.ref, Validators.required],
    });
  }

  addManager(form: FormGroup){
    // console.log(form.value);
    if(form.valid){
      this.accountsService.createManager(form.value).subscribe(
        data => {
          // console.table(data);
          this.toast.success('Manager Account Created.');
          // this.router.navigate(['/login']);
          $('#add').modal('hide');
          this.getManagers();
        },
        err => {
          console.error(err);
          if(err.status == 0){
            this.toast.error('Network Connection Failure');
          }
           else if(err.error?.message){
            this.toast.error(err.error.message);
          }else if(err.status == 500){
            this.toast.error('Internal Server Error')
          }
          else{
            this.toast.error('Request Failed: ' + err.status);
          }
        }
      );
    }
    else{
      this.toast.warning('Missing Required Info.');
    }
  }

}
