import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  roles: any;
  addForm: FormGroup;

  constructor(
    private userService : UsersService,
    private toast : ToastrService,
    private fb : FormBuilder,
    private router : Router
  ) { }

  ngOnInit(): void {
    this.getRoles();

    this.addForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', Validators.required],
      userName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      roleId: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  getRoles(){
    this.userService.getAllRoles().subscribe(
      data => {
        // console.table(data);

        this.roles = data;
      },
      err => {
        console.error(err);
        if(err.status == 0){
          this.toast.error('Network Connection Failure');
        }
         else if(err.error?.message){
          this.toast.error(err.error.message);
        }else if(err.status == 403 || err.status == 401){
          this.toast.error("You don't have Permissions to access this Resource.");
        }else{
          this.toast.error('An Error Occured. Contact System Admin');
        }
      }
    )
  }

  createAccount(form: FormGroup){
    // console.log(form.value);

    if(!this.validatePasswords(form.value.password)){
      this.toast.error("Weak Password. (8 Characters with 1 Capital, 1 Number and 1 Symbol)");
      return false;
    }

    if(form.valid){
      // this.userService.userSignUp(form.value).subscribe(
      //   resp => {
      //     // console.log(resp);

      //     this.toast.success("Account created successfully");
      //     this.router.navigate(['/login']);
      //   },
      //   err => {
      //     console.error(err);
      //     if(err.status == 0){
      //       this.toast.error('Network Connection Failure');
      //     }
      //      else if(err.error?.message){
      //       this.toast.error(err.error.message);
      //     }else if(err.status == 500){
      //       this.toast.error('Internal Server Error')
      //     }
      //     else{
      //       this.toast.error('Request Failed: ' + err.status);
      //     }
      //   }
      // );
    }
    else{
      this.toast.warning("Missing Required Info");
    }
    
  }

  validatePasswords(pwd){
    const rd = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return rd.test(pwd);
  }

  public removeUnderscore(str: string) {
    return str.replace(/_/g, " ");
  }

}
