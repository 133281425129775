<div class="az-content-left az-content-left-components">
    <div class="component-item">
      
      <label>Stations</label> <br>
      <nav class="nav flex-column">
        <a routerLink="/admin/stations/all" routerLinkActive="active" class="nav-link">Service Stations</a>
      </nav>

      <br><br>

      <label>Others</label> <br>
      <nav class="nav flex-column">
        <a routerLink="/admin/stations/attendants" routerLinkActive="active" class="nav-link disabled">Attendants</a>
        <a routerLink="/admin/stations/products" routerLinkActive="active" class="nav-link disabled">Products</a>
        <a routerLink="/admin/stations/terminals" routerLinkActive="active" class="nav-link disabled">Terminals</a>
      </nav>

    </div><!-- component-item -->

</div>